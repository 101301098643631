<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>商戶列表</el-breadcrumb-item>
                <el-breadcrumb-item @click="goBack"
                                    class="go-back-font">專案列表</el-breadcrumb-item>
                <el-breadcrumb-item>編輯專案</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="form-box">
                <div class="form-title">編輯專案</div>
                <FormTemplate :formParams="form"
                              :deafulcomForm="deafuleditForm"
                              @reset-form="resetForm"
                              :btnText="'重填'"
                              @on-submit="onSubmit" />
            </div>
        </div>
    </div>
</template>

<script>
import FormTemplate from '../../../../components/FormTemplate.vue'
import { updateProjectList, updateProject } from '../../../../api/index'
export default {
    components: {
        FormTemplate,
    },
    data() {
        return {
            userId: '',
            projectId: '',
            form: {
                labelWidth: '150px',
                formList: [
                    {
                        label: '專案名稱',
                        key: 'name',
                        type: 'INPUT',
                        placeholder: '請輸入專案名稱',
                        require: true,
                        span: 24,
                    },
                    {
                        label: '專案排序',
                        key: 'sort',
                        type: 'INPUT',
                        placeholder: '請輸入專案排序',
                        require: true,
                        span: 24,
                    },
                ],
            },
            deafuleditForm: {},
        }
    },
    mounted() {
        if (typeof this.$route.query.id == 'undefined') {
            this.$router.push({ path: 'commercialList' })
        } else {
            this.userId = this.$route.query.id
            this.projectId = this.$route.query.projectId
            this.getData()
        }
    },
    methods: {
        getData() {
            updateProjectList({ id: this.projectId })
                .then((res) => {
                    if (res.code == 300) {
                        this.deafuleditForm = res.data.data_info
                    }
                })
                .catch(() => {
                    this.$message.error('失敗')
                })
        },
        onSubmit(form) {
            form.merchant_id = this.userId
            updateProject(form)
                .then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                        this.goBack()
                    } else if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error('失敗')
                })
        },
        resetForm() {
            this.deafuleditForm = {}
        },
        goBack() {
            this.$router.push({
                path: 'projectList',
                query: { id: this.userId },
            })
        },
    },
}
</script>

<style lang="scss">
.go-back-font {
    .el-breadcrumb__inner {
        font-weight: 700;
        color: #303133;
    }
    .el-breadcrumb__inner:hover {
        color: #409eff;
        cursor: pointer;
    }
}
</style>
<style lang="scss" scoped>
</style>